.formulario {
	display: flex;
	align-items: flex-start;
	margin: 0 50px 40px;
	justify-content: center;
}

.formulario form {
	width: 100%;
	max-width: 600px;
	background-color: #f6f6f6;
	border-radius: 20px;
	box-shadow: 7px 7px 15px rgba(0, 0, 0, 0.25);
	padding: 8px 50px;
	box-sizing: border-box;
}

.formulario h2 {
	font-size: 32px;
	font-weight: 400;
	color: #212121;
	font-family: "Prata", serif;
	text-align: center;
}

.form-equipo {
	margin-left: 20px;
}
.form-cola {
	margin-right: 20px;
}

@media screen and (max-width: 768px) {
	.formulario {
		margin: 0 0 30px;
		flex-direction: column;
		align-items: center;
	}

	.formulario form {
		width: 90%;
		padding: 8px 20px;
	}
	.formulario h2 {
		font-size: 22px;
	}
	.form-equipo {
		margin-left: 0;
		order: 1;
	}
	.form-cola {
		order: 2;
		margin-top: 30px;
		margin-right: 0;
	}
	.campo-texto:last-of-type {
		margin-bottom: 0px;
	}
}
