.campo {
	margin: 24px 0;
}

.campo label {
	font-size: 18px;
	color: #212121;
	font-weight: 600;
	display: block;
	margin-bottom: 8px;
	font-family: "Montserrat", sans-serif;
}

.campo input {
	font-family: "Montserrat", sans-serif;
	font-size: 16px;
	width: 100%;
	box-sizing: border-box;
	background-color: #ffffff;
	box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.15);
	border: none;
	padding: 30px 20px;
	outline-color: #6278f7;
}

.campo input::placeholder {
	color: #a4a4a4;
}

.campo-color {
	margin-bottom: 12px;
}
.campo-color input {
	width: 80px;
	padding: 0px 2px;
	height: 40px;
}

@media screen and (max-width: 768px) {
	.campo {
		margin: 12px 0;
	}

	.campo label {
		font-size: 14px;
		margin-bottom: 6px;
	}

	.campo input {
		font-size: 14px;
		padding: 20px 10px;
	}
	.campo-color {
		margin-bottom: 0px;
	}
	.campo-color input {
		padding: 0px 2px;
	}
}
