.colaborador {
	display: flex;
	flex-direction: column;
	width: 256px;
	text-align: center;
	min-height: 278px;
	margin: 0px 12px 30px;
	box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.25);
	border-radius: 15px;
	position: relative;
}

.colaborador .encabezado {
	border-radius: 15px 15px 0px 0px;
	height: 90px;
}

.foto-Cola {
	width: 120px;
	border-radius: 50%;
	position: relative;
	top: -60px;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
	z-index: 1;
}

.colaborador .info {
	background: #ffffff;
	border-radius: 0px 0px 15px 15px;
	min-height: 100px;
	font-family: "Montserrat", sans-serif;
	flex-grow: 1;
}

.colaborador .info h4 {
	color: #6278f7;
	font-size: 18px;
	line-height: 22px;
	font-weight: 600;
	margin: -33px 0 0;
	padding: 0 32px;
}

.colaborador .info h5 {
	font-size: 16px;
	font-weight: 400;
	color: #212121;
	margin: 10px 0 0;
	padding: 0 32px 27px;
}

.corazon-vacio {
	position: absolute;
	width: 35px;
	height: 35px;
	right: 65px;
	top: 30px;
	z-index: 2;
	cursor: pointer;
}

.corazon-lleno {
	position: absolute;
	width: 35px;
	height: 35px;
	right: 65px;
	top: 30px;
	z-index: 2;
	cursor: pointer;
}

.boton-eliminar {
	display: block;
	cursor: pointer;
	width: 40px;
	position: absolute;
	top: -15px;
	left: -15px;
}

.boton-eliminar :hover {
	content: attr(title);
}
