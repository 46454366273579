.div-org {
	display: flex;
	justify-content: center;
}

.orgSection {
	width: 100%;
	max-width: 1120px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin: 10px 0;
}

.title {
	font-family: "Prata", serif;
	margin: 0;
	font-size: 40px;
	font-weight: 400;
	color: #6278f7;
	padding: 0 0 6px;
	border-bottom: 4px solid #6278f7;
}

.orgSection img {
	cursor: pointer;
}

@media screen and (max-width: 768px) {
	.orgSection {
		width: 95%;
	}

	.title {
		font-size: 25px;
	}
	.orgSection img {
		width: 60px;
	}
}
