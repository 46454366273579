.boton {
	background-color: #6278f7;
	border-radius: 10px;
	font-weight: 700;
	font-family: "Montserrat", sans-serif;
	font-size: 20px;
	padding: 24px 32px;
	border: none;
	margin: 28px 0;
	cursor: pointer;
	color: #ffffff;
}

.boton:hover {
	color: #95ffd4;
}

@media screen and (max-width: 768px) {
	.boton {
		font-size: 16px;
		padding: 15px 20px;
		margin: 14px 0;
	}
}
