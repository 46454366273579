.div-footer {
	display: flex;
	justify-content: center;
	margin-top: auto;
	background-color: #6278f7;
	min-height: 120px;
	height: 120px;
	padding: 0 50px;
	background-repeat: no-repeat;
	/* background-image: url("../../../public/img/footer.png"); */
	background-position: center;
}

.footer {
	width: 100%;
	max-width: 1120px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-size: 100%;
}

.footer > * {
	width: 220px;
	text-align: center;
}
.redes {
	display: flex;
	justify-content: center;
}

.redes a {
	display: flex;
	align-items: center;
	text-decoration: none;
}

.redes a strong {
	border-bottom: 2px solid white;
	margin-left: 10px;
}

.footer strong {
	color: #ffffff;
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 25px;
	text-align: center;
}

.redes img {
	width: 100px;
	border-radius: 50%;
}

.by-text1 {
	width: 140px;
	display: inline-block;
}

.by-text2 {
	display: none;
}

/* will apply to mobile and ipad */
@media screen and (max-width: 768px) {
	.footer > * {
		width: 93px;
	}

	.div-footer {
		padding: 0 15px;
		height: 80px;
		min-height: 80px;
	}

	.footer strong {
		font-size: 22px;
	}

	.redes img {
		width: 70px;
	}

	.redes a strong {
		display: none;
	}

	.by-text1 {
		display: none;
	}

	.by-text2 {
		display: inline-block;
	}
}
