.div-equipo {
	display: flex;
	justify-content: center;
}

.equipo {
	width: 100%;
	max-width: 1120px;
	display: flex;
	flex-direction: column;
	position: relative;
}

.equipo h3 {
	font-family: "Prata", serif;
	font-size: 32px;
	font-weight: 400;
	color: #212121;
	margin: 20px 0;
	border-bottom: 4px solid;
	padding-bottom: 3px;
	align-self: center;
}

.colaboradores {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.input-destaques {
	position: absolute;
	top: 25px;
	right: 50px;
}

.input-destaques {
	position: absolute;
	top: 25px;
	right: 50px;
	cursor: pointer;
}

@media screen and (max-width: 768px) {
	.equipo {
		width: 95%;
	}
	.equipo h3 {
		font-size: 23px;
		word-break: keep-all;
		width: calc(100vw - 170px);
		text-align: center;
		border-bottom: none;
		text-decoration: underline;
		text-decoration-thickness: 3px;
	}

	.input-destaques {
		position: absolute;
		top: 25px;
		right: 20px;
	}
}
