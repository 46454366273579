.lista-opciones {
	margin: 24px 0 12px;
}

.lista-opciones label {
	font-family: "Montserrat", sans-serif;
	font-size: 18px;
	color: #212121;
	font-weight: 600;
	display: block;
	margin-bottom: 8px;
}

.lista-opciones select {
	font-family: "Montserrat", sans-serif;
	font-size: 16px;
	width: 100%;
	background-color: #ffffff;
	box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.15);
	border: none;
	padding: 30px 20px;
	outline-color: #6278f7;
	color: #212121;
}

@media screen and (max-width: 768px) {
	.lista-opciones {
		margin: 12px 0 0;
	}

	.lista-opciones label {
		font-size: 14px;
	}

	.lista-opciones select {
		font-size: 14px;
		padding: 20px 10px;
	}
}
